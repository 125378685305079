import React from "react"
import Layout from "@components/layout";

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/economic-calender.json";
import langJa from "@content/locales/ja/economic-calender.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const EconomicCalender = ( props ) => {

  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // Seo情報設定
  const pathName = 'economic-calender'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );

  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  
  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["eco-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["eco-h1-txt"]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-xl mx-auto  pt-0 md:pt-5 pb-11 md:max-w-5xl px-6 mb-8 md:mb-10">
        <div className="text-left mt-10 md:mt-7 mb-10">
          <h2 className="font-semibold  tracking-wide text-[26px] md:text-[40px]">{postLang["eco-h1-2"]}</h2>
        </div>
        <div className="max-w-xl md:mx-auto md:max-w-5xl mb-3"> <iframe scrolling="no" src={postLang[ "eco-iframe"]} width="100%" height="400" title="経済指標"></iframe></div>
      </div>
      <div className="Feature  bg-fixed">
        <div className="mt-11 py-14 py-14  md:py-10 md:py-10"> 
        <div className="md:mx-auto max-w-5xl flex justify-center"><a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a> </div>
      </div></div>
    </Layout>
  </>
  )
}
export default EconomicCalender